import { Box, type MantineStyleProp, Text } from '@mantine/core';

import classes from './GeneratedResponseCard.module.css';

import type { RequirementResponse } from '../../types/apiTypes';
import { ResponseTextEditor } from '../ResponseTextEditor';

interface GeneratedResponseCardProps {
  requirementResponse: RequirementResponse
  style?: MantineStyleProp
}

const GeneratedResponseCard: React.FC<GeneratedResponseCardProps> = ({
  requirementResponse,
  style
}: GeneratedResponseCardProps) => (
  <Box
    style={style}
    className={classes.card}
  >
    <Box pl={16} pr={16} pt={8} pb={8}>
      <Text>{requirementResponse.requirement?.text}</Text>
    </Box>
    <ResponseTextEditor
      initialContent={requirementResponse?.content ?? ''}
      proposalSectionUid={requirementResponse?.proposal_section?.uid ?? ''}
      canEdit={false}
      contentStyleName={classes.content}
    />
  </Box>
);

export default GeneratedResponseCard;
