import { Box, Modal } from '@mantine/core';

import {
  DragDropContext,
  Draggable,
  Droppable,
  type DragStart,
  type DropResult,
  type BeforeCapture
} from '@hello-pangea/dnd';

import { useDisclosure } from '@mantine/hooks';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { type AxiosError } from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { type SectionData } from '../../Pages/Editor/SectionEditor';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import { defaultModalProps, showFailureNotification } from '../../utils/mantineUtils';
import {
  AddRequirement,
  DeleteSection,
  EditRequirement,
  RenameSection
} from '../Modals';
import { handlePotentialLockError } from '../../utils/apiUtils';
import {
  reorderSection,
  reorderRequirement,
  updateOutlineSections,
  updateOutlineRequirements,
  moveRequirement,
  editRequirement,
  reorderSubesction,
  moveSubsection,
  deleteSection
} from '../../redux/OutlineSlice';
import { selectOutline } from '../../redux/store';
import DroppableSection from './DroppableSection';
import { type OpportunityRequirement, type RequirementResponse } from '../../types/apiTypes';
import { ARCHIVED_SECTION_DATA } from '../../utils/stringUtils';
import {
  getAdjustedRequirementIndex,
  getItemInfoFromDraggableId,
  getSectionUidFromDroppableId,
  undoDraggableSubsectionIndex
} from '../../utils/dndUtils';
import {
  formatRequirementData,
  type RequirementType,
  type RequirementRemovalType,
  DEFAULT_EMPTY_REQUIREMENT
} from '../../utils/requirementUtils';

interface Props {
  sectionWidth: string
  sections: SectionData[]
  requirementResponses: RequirementResponse[]
  opportunityUid: string
  onRowClick: (requirementUid: string) => void
  focusedRequirementUid?: string
  showAddRequirement?: boolean
  onOpenAddSection: (parentSectionUid: string) => void
  showRequirements: boolean
  checkedRequirements?: RequirementType[]
  onCheckRequirement?: (requirement: RequirementType) => void
  showAssignedUsers: boolean
  requirementRemovalType: RequirementRemovalType
}

const EditOutlineDnD: React.FC<Props> = (props: Props) => {
  const {
    sections,
    focusedRequirementUid,
    requirementResponses,
    opportunityUid,
    onOpenAddSection,
    requirementRemovalType,
    showAddRequirement = true
  } = props;
  const [currentSectionUid, setCurrentSectionUid] = useState('');
  const [currentRequirement, setCurrentRequirement] = useState<RequirementType>(DEFAULT_EMPTY_REQUIREMENT);

  const [editRequirementOpened, editRequirementHandlers] = useDisclosure();
  const [deleteOpened, deleteHandlers] = useDisclosure();
  const [renameOpened, renameHandlers] = useDisclosure();
  const [addRequirementOpened, addRequirementHandlers] = useDisclosure();
  /* controls whether not archive section can have items dropped into it.
     set "true" when a requirement is being dragged, "false" when a subsection is being dragged
  */
  const [archiveIsDroppable, setArchiveIsDroppable] = useState(true);
  const outlineState = useSelector(selectOutline);
  const dispatch = useDispatch();
  const { sectionUid: sectionUidOfCurrentPage, proposalUid } = useParams();
  const [sectionsOpened, setSectionsOpened] = useState<Set<string>>(new Set());
  const [draggingRequirementResponseUid, setDraggingRequirementResponseUid] = useState<string | undefined>(undefined);

  const showArchived = requirementRemovalType === 'ARCHIVE';

  const handleSetSectionOpen = (sectionUid: string, shouldSetOpen?: boolean) => {
    const addSection = shouldSetOpen ?? !sectionsOpened.has(sectionUid);
    if (addSection) {
      setSectionsOpened(prevState => new Set(prevState.add(sectionUid)));
    } else {
      setSectionsOpened(prevState => {
        const newSet = new Set(prevState);
        newSet.delete(sectionUid);
        return newSet;
      });
    };
  };

  const handleOpenDelete = useCallback((sectionUid: string) => {
    setCurrentSectionUid(sectionUid);
    deleteHandlers.open();
  }, [deleteHandlers]);

  const handleOpenRename = useCallback((sectionUid: string) => {
    setCurrentSectionUid(sectionUid);
    renameHandlers.open();
  }, [renameHandlers]);

  const handleOpenAddRequirement = useCallback((sectionUid: string) => {
    setCurrentSectionUid(sectionUid);
    addRequirementHandlers.open();
  }, [addRequirementHandlers]);

  const onDeleteSectionSuccess = useCallback((sectionToEdit: SectionData) => {
    sectionToEdit.childSections?.forEach((childSection) => {
      dispatch(deleteSection(childSection));
    });
    dispatch(deleteSection(sectionToEdit));
  }, []);

  useEffect(() => {
    const formattedRequirements = formatRequirementData(requirementResponses);
    dispatch(updateOutlineSections({ sections }));
    dispatch(updateOutlineRequirements({ requirements: formattedRequirements }));
  }, []);

  const handleOpenEditRequirement = useCallback((requirement: RequirementType, sectionUid: string) => {
    setCurrentRequirement(requirement);
    setCurrentSectionUid(sectionUid);
    editRequirementHandlers.open();
  }, [editRequirementHandlers]);
  const inBulkEditMode = (props.checkedRequirements?.length ?? 0) > 0;
  const sectionItems = outlineState.sections.filter((section) => section.uid !== 'null').map((section, index) =>
    <Draggable key={section.uid} isDragDisabled={inBulkEditMode} index={index} draggableId={`section_${section.uid}`}>
      {(sectionDragProvided, snapshot) =>
        <Box
          ref={sectionDragProvided.innerRef}
          {...sectionDragProvided.draggableProps}
          style={{
            cursor: inBulkEditMode ? 'pointer' : 'grab',
            alignItems: 'center',
            ...sectionDragProvided.draggableProps.style
          }}
        >
          <DroppableSection
            section={section}
            onOpenDelete={handleOpenDelete}
            onOpenRename={handleOpenRename}
            onOpenAddRequirement={showAddRequirement ? handleOpenAddRequirement : undefined}
            onOpenEditRequirement={handleOpenEditRequirement}
            sectionDragProvided={sectionDragProvided}
            onOpenAddSubsection={onOpenAddSection}
            focusedRequirementUid={focusedRequirementUid}
            handleSetSectionOpen={handleSetSectionOpen}
            sectionsOpened={sectionsOpened}
            archiveIsDroppable={archiveIsDroppable}
            draggingRequirementResponseUid={draggingRequirementResponseUid}
            {...props}
          />
        </Box>
      }
    </Draggable>);

  const archivedSectionItems = (
    <Draggable
      key={ARCHIVED_SECTION_DATA.uid}
      index={sectionItems.length}
      draggableId={`section_${ARCHIVED_SECTION_DATA.uid}`}
    >
      {(sectionDragProvided, snapshot) =>
        <Box ref={sectionDragProvided.innerRef} {...sectionDragProvided.draggableProps}>
          <DroppableSection
            sectionDragProvided={sectionDragProvided}
            section={ARCHIVED_SECTION_DATA}
            onOpenDelete={handleOpenDelete}
            onOpenAddRequirement={handleOpenAddRequirement}
            onOpenRename={handleOpenRename}
            onOpenEditRequirement={handleOpenEditRequirement}
            onOpenAddSubsection={onOpenAddSection}
            isArchive={true}
            sectionsOpened={sectionsOpened}
            handleSetSectionOpen={handleSetSectionOpen}
            archiveIsDroppable={archiveIsDroppable}
            draggingRequirementResponseUid={draggingRequirementResponseUid}
            {...props}
            showAssignedUsers={false}
          />
        </Box>
      }
    </Draggable>);

  const editSectionMutation = useEnsisMutation(`/app/proposal-sections/${currentSectionUid}/data`, {
    requestType: 'patch',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
  });

  const editRequirementResponseMutation = useEnsisMutation('/app/requirement-responses',
    {
      contentType: 'application/json',
      requestType: 'patch',
      showSuccessMessage: false,
      queryKeysToInvalidate: [
        `/app/proposals/${proposalUid}/sections/${sectionUidOfCurrentPage}/requirement-responses`,
        '/app/requirement-responses'
      ]
    });

  const previousRequirements = useMemo(() => Object.values(outlineState.requirements).flat(1), [outlineState]);

  const getIsDuplicateTitle = (sectionTitle: string, parentSectionUid: string) => {
    return outlineState.subsections[parentSectionUid].map(
      (section) => section.title.toLowerCase()
    ).includes(sectionTitle.toLowerCase());
  };

  const handleOnSubsectionReorderError = useCallback((
    error: AxiosError,
    parentSectionUid: string,
    fromIndex: number,
    toIndex: number
  ) => {
    dispatch(reorderSubesction({
      parentSectionUid,
      fromIndex: toIndex,
      toIndex: fromIndex
    }));
    handlePotentialLockError(error, 'Unable to move section: A teammate is currently editing this section');
  }, []);
  const handleOnSubsectionMoveError = useCallback((
    error: AxiosError,
    toSectionUid: string,
    fromSectionUid: string,
    fromIndex: number,
    toIndex: number
  ) => {
    dispatch(moveSubsection({
      fromSectionUid: toSectionUid,
      toSectionUid: fromSectionUid,
      fromIndex: toIndex,
      toIndex: fromIndex
    }));
    handlePotentialLockError(error, 'Unable to move section: A teammate is currently editing this section');
  }, []);

  const handleRequirementMove = useCallback((result: DropResult) => {
    if (result.destination?.index !== undefined) {
      const destinationSectionUid = getSectionUidFromDroppableId(result.destination.droppableId);
      const fromIndex = result.source.index;
      const toIndex = getAdjustedRequirementIndex(destinationSectionUid, result.destination.index);
      const { uid: requirementResponseUid } = getItemInfoFromDraggableId(result.draggableId);
      dispatch(moveRequirement({
        toIndex,
        fromIndex,
        toSectionUid: getSectionUidFromDroppableId(result.destination?.droppableId),
        fromSectionUid: getSectionUidFromDroppableId(result.source.droppableId)
      }));
      editRequirementResponseMutation.mutate(
        {
          edits: [{
            requirement_response_uid: requirementResponseUid,
            new_ordinal: toIndex + 1,
            new_section_uid: destinationSectionUid === ARCHIVED_SECTION_DATA.uid ? null : destinationSectionUid,
            remove_from_section: destinationSectionUid === ARCHIVED_SECTION_DATA.uid,
            is_compliant: false,
            section_text_references: []
          }],
          proposal_uid: proposalUid ?? ''
        }, {
          onError: (error: AxiosError) => {
            dispatch(updateOutlineRequirements({ requirements: previousRequirements }));
            handlePotentialLockError(error, 'Unable to move requirement: A teammate is currently editing this section');
          }
        }
      );
    }
  }, [editRequirementResponseMutation]);

  const handleSubsectionReorder = useCallback((result: DropResult) => {
    if (result.destination?.index !== undefined && result.destination?.index !== result.source.index) {
      const parentSectionUid = getSectionUidFromDroppableId(result.destination.droppableId);
      const fromIndex = undoDraggableSubsectionIndex(parentSectionUid, result.source.index);
      const toIndex = undoDraggableSubsectionIndex(parentSectionUid, result.destination.index);
      dispatch(reorderSubesction(
        {
          parentSectionUid,
          fromIndex,
          toIndex
        }
      ));
      editSectionMutation.mutate(
        {
          ordinal: toIndex + 1
        }, {
          onError: (error: AxiosError) => {
            handleOnSubsectionReorderError(error, parentSectionUid, fromIndex, toIndex);
          }
        }
      );
    }
  }, []);

  const handleSubsectionMove = useCallback((result: DropResult) => {
    if (result.destination?.index !== undefined) {
      const fromSectionUid = getSectionUidFromDroppableId(result.source.droppableId);
      const toSectionUid = getSectionUidFromDroppableId(result.destination.droppableId);
      const fromIndex = undoDraggableSubsectionIndex(fromSectionUid, result.source.index);
      const toIndex = undoDraggableSubsectionIndex(toSectionUid, result.destination.index);
      const isDuplicateTitle = getIsDuplicateTitle(
        outlineState.subsections[fromSectionUid][fromIndex].title, toSectionUid
      );
      if (isDuplicateTitle) {
        showFailureNotification('Subsection title already exists in this section');
        return;
      }
      dispatch(moveSubsection(
        {
          fromSectionUid,
          toSectionUid,
          fromIndex,
          toIndex
        }
      ));
      editSectionMutation.mutate(
        {
          ordinal: toIndex + 1,
          parent_proposal_section_uid: toSectionUid
        }, {
          onError: (error: AxiosError) => {
            handleOnSubsectionMoveError(error, toSectionUid, fromSectionUid, fromIndex, toIndex);
          }
        }
      );
    }
  }, [outlineState]);

  const handleRequirementReorder = useCallback((result: DropResult) => {
    if (result.destination?.index !== undefined && result.destination?.index !== result.source.index) {
      const sectionUid = getSectionUidFromDroppableId(result.destination.droppableId);
      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      const requirementResponseUid = getItemInfoFromDraggableId(result.draggableId).uid;
      if (sectionUid === ARCHIVED_SECTION_DATA.uid) {
        showFailureNotification('Archived requirements cannot be reordered');
      } else {
        dispatch(reorderRequirement(
          {
            sectionUid,
            fromIndex,
            toIndex
          }
        ));
        editRequirementResponseMutation.mutate(
          {
            edits: [{
              requirement_response_uid: requirementResponseUid,
              new_ordinal: toIndex + 1
            }],
            proposal_uid: proposalUid ?? ''
          },
          {
            onError: (error: AxiosError) => {
              dispatch(updateOutlineRequirements({ requirements: previousRequirements }));
              handlePotentialLockError(
                error,
                'Unable to move requirement: A teammate is currently editing this section'
              );
            }
          }
        );
      }
    }
  }, [editRequirementResponseMutation, proposalUid]);

  const handleSectionReorder = useCallback((result: DropResult) => {
    if (result.destination?.index !== undefined && result.destination?.index !== result.source.index) {
      const prevState = [...outlineState.sections];
      if (result.source.index === outlineState.sections.length - 1) {
        showFailureNotification('Archived requirements cannot be reordered');
      } else if (result.destination.index === outlineState.sections.length - 1) {
        // handle user attempting to move after archived
        const toIndex = outlineState.sections.length - 2;
        dispatch(reorderSection({ fromIndex: result.source.index, toIndex }));
        editSectionMutation.mutate(
          {
            ordinal: toIndex + 1
          }, {
            onError: (error: AxiosError) => {
              dispatch(updateOutlineSections({ sections: prevState }));
              handlePotentialLockError(error, 'Unable to move section: A teammate is currently editing this section');
            }
          }
        );
      } else {
        dispatch(reorderSection({ fromIndex: result.source.index, toIndex: result.destination.index }));
        editSectionMutation.mutate(
          {
            ordinal: result.destination?.index + 1
          }, {
            onError: (error: AxiosError) => {
              dispatch(updateOutlineSections({ sections: prevState }));
              handlePotentialLockError(error, 'Unable to move section: A teammate is currently editing this section');
            }
          }
        );
      }
    }
  }, [editSectionMutation]);

  const onEditRequirement = useCallback((requirement: OpportunityRequirement) => {
    const formattedRequirement = {
      requirementText: requirement.text,
      requirementUid: requirement.uid,
      requirementIdentifier: requirement.identifier ?? '',
      sectionUid: currentSectionUid ?? ''
    };
    dispatch(editRequirement(formattedRequirement));
    // remove compliance status when requirement text is edited
    editRequirementResponseMutation.mutate({
      proposal_uid: proposalUid ?? '',
      edits: [{
        requirement_response_uid: currentRequirement.requirementResponseUid,
        is_compliant: false,
        section_text_references: []
      }]
    });
  }, [proposalUid, currentRequirement]);

  // closes section if it opened when dragging
  const handleOnBeforeDragStart = (start: BeforeCapture) => {
    const { type, uid } = getItemInfoFromDraggableId(start.draggableId);
    if (type === 'section' || type === 'subsection') {
      handleSetSectionOpen(uid, false);
    } else {
      setDraggingRequirementResponseUid(uid);
    }
  };
  const handleOnDragStart = (start: DragStart) => {
    const { uid, type } = getItemInfoFromDraggableId(start.draggableId);
    if (type === 'section') {
      setCurrentSectionUid(uid);
    } else if (type === 'subsection') {
      setCurrentSectionUid(uid);
      setArchiveIsDroppable(false);
    } else {
      setArchiveIsDroppable(true);
    }
  };

  const handleDragEnd = useCallback((result: DropResult) => {
    const { type } = getItemInfoFromDraggableId(result.draggableId);
    if (type === 'section') {
      handleSectionReorder(result);
    } else if (type === 'subsection') {
      if (result.destination?.droppableId === result.source.droppableId) {
        handleSubsectionReorder(result);
      } else {
        handleSubsectionMove(result);
      }
    } else if (result.destination?.droppableId === result.source.droppableId) { // type === req
      handleRequirementReorder(result);
      setDraggingRequirementResponseUid(undefined);
    } else {
      handleRequirementMove(result);
      setDraggingRequirementResponseUid(undefined);
    }
  }, [handleSectionReorder, handleRequirementReorder, handleRequirementMove]);

  return (
    <>
      <Modal opened={deleteOpened} {...defaultModalProps}>
        <DeleteSection
          onDeleteSuccess={onDeleteSectionSuccess}
          sections={sections}
          close={deleteHandlers.close}
          sectionToEditUid={currentSectionUid}
        />
      </Modal>
      <Modal opened={renameOpened} {...defaultModalProps}>
        <RenameSection
          isOutline
          close={renameHandlers.close}
          sectionToEdit={sections.filter((section) => section.uid === currentSectionUid)[0]}
          sections={sections}
        />
      </Modal>
      {
        <Modal opened={editRequirementOpened} {...defaultModalProps}>
          <EditRequirement
            onEditRequirement={onEditRequirement}
            requirement={currentRequirement}
            close={editRequirementHandlers.close}
            opportunityUid={opportunityUid}
          />
        </Modal>
      }
      <Modal opened={addRequirementOpened} {...defaultModalProps}>
        <AddRequirement
          updateReduxOnSuccess
          sectionToEditUid={currentSectionUid}
          close={addRequirementHandlers.close}
          opportunityUid={opportunityUid}
        />
      </Modal>
      <DragDropContext
        onBeforeCapture={handleOnBeforeDragStart}
        onDragStart={handleOnDragStart}
        onDragEnd={handleDragEnd}
      >
        <Droppable type='SECTION' droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sectionItems}
              {showArchived && archivedSectionItems}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default EditOutlineDnD;
