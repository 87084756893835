/* eslint-disable max-len */
import { type SVGProps } from 'react';

const DownloadProposal: React.FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
<svg width="20" height="17" viewBox="0 0 20 17" fill={props.color ?? 'var(--mantine-color-darkPurple-9)'} stroke={props.color ?? 'var(--mantine-color-darkPurple-9)'} xmlns="http://www.w3.org/2000/svg">
<path d="M18.5 15V10.5H19.5V15C19.5 15.8339 18.8339 16.5 18 16.5H2C1.17451 16.5 0.5 15.8322 0.5 15V10.5H1.5V15V15.5H2H18H18.5V15ZM9.5 0.5H10.5V9.25V10.4544L11.353 9.60412L14.1307 6.8353L14.8387 7.53835L9.99999 12.2986L5.17001 7.54709L5.88 6.83711L8.64645 9.60355L9.5 10.4571V9.25V0.5Z"/>
</svg>
  );
};

export default DownloadProposal;
