import { Button, Divider, Group, Text, Anchor, Popover } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useCallback, useState } from 'react';

import { notifications } from '@mantine/notifications';

import { type AxiosError } from 'axios';

import { type SectionData } from '../../Pages/Editor/SectionEditor';
import { Trash } from '../../icons';
import { type RouteWithGet } from '../../types/apiTypes';
import useEnsisMutation from '../../hooks/useEnsisMutation';

import { selectOutline } from '../../redux/store';

import { moveRequirement, updateOutlineRequirements } from '../../redux/OutlineSlice';
import { ARCHIVED_SECTION_DATA } from '../../utils/stringUtils';

import { handlePotentialLockError } from '../../utils/apiUtils';
import FileMoveOutline from '../../icons/FileMoveOutline';
import { MoveRequirementMenu } from '../DropdownMenus/RequirementDropdown';
import { type RequirementType, type RequirementRemovalType } from '../../utils/requirementUtils';

interface Props {
  onClearCheckedRequirements: () => void
  checkedRequirements: RequirementType[]
  sections: SectionData[]
  requirementRemovalType: RequirementRemovalType
  onOpenDeleteRequirements: () => void
  moveButtonCopy: string
}
const BulkRequirementButtonGroup: React.FC<Props> = (props: Props) => {
  const {
    onClearCheckedRequirements,
    onOpenDeleteRequirements,
    checkedRequirements,
    requirementRemovalType,
    sections,
    moveButtonCopy
  } = props;

  const { proposalUid } = useParams();

  const dispatch = useDispatch();

  const outlineState = useSelector(selectOutline);
  const [previousRequirements, setPreviousRequirements] = useState<RequirementType[]>([]);

  const allSectionRoutes = sections.map(
    (section) => `/app/proposals/${proposalUid}/sections/${section.uid}/requirement-responses` as RouteWithGet
  );
  /*
  filters out requirements which are already in the new section and orders requirements
  by section and then descending ordinal, to ensure that requirements are moved in
  the correct order since requirements are moved based on index (to make drag and drop work).
*/
  const prepRequirementsForMove = (checkedRequirements: RequirementType[], newSectionUid: string) => {
    return checkedRequirements.filter((requirement) =>
      requirement.sectionUid !== newSectionUid).sort((a, b) => {
      if (a.sectionUid < b.sectionUid) {
        return -1;
      } else if (a.sectionUid > b.sectionUid) {
        return 1;
      }
      const aFromIndex = outlineState.requirements[a.sectionUid]?.findIndex(
        (_requirement) => _requirement.requirementResponseUid === a.requirementResponseUid
      );
      const bFromIndex = outlineState.requirements[b.sectionUid]?.findIndex(
        (_requirement) => _requirement.requirementResponseUid === b.requirementResponseUid
      );
      return bFromIndex - aFromIndex;
    });
  };

  const undoMoveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    awaitRefetch: false,
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    successMessage: 'Changes reverted'
  });

  const handleUndoMoveRequirements = useCallback(() => {
    notifications.clean();
    const edits = checkedRequirements.map((requirement) => {
      const movingToArchived = requirement.sectionUid === ARCHIVED_SECTION_DATA.uid;
      return {
        requirement_response_uid: requirement.requirementResponseUid,
        new_ordinal: requirement.ordinal,
        new_section_uid: movingToArchived ? undefined : requirement.sectionUid,
        remove_from_section: movingToArchived
      };
    });
    dispatch(updateOutlineRequirements({ requirements: previousRequirements }));
    undoMoveRequirementResponseMutation.mutate({
      edits,
      proposal_uid: proposalUid ?? ''
    });
  }, [undoMoveRequirementResponseMutation, previousRequirements, checkedRequirements]);

  const onError = (error: AxiosError) => {
    dispatch(updateOutlineRequirements({ requirements: previousRequirements }));
    handlePotentialLockError(error, 'Unable to move requirement: A teammate is currently editing this section');
  };

  const archiveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    awaitRefetch: false,
    successMessage: (
      <Text fz='sm'> {'Requirements archived. '}
        <Anchor fz='sm' onClick={handleUndoMoveRequirements}>
          Undo
        </Anchor>
      </Text>)
  });

  const handleArchiveRequirements = useCallback(() => {
    setPreviousRequirements(Object.values(outlineState.requirements).flat(1));
    const requirementsToMove = prepRequirementsForMove(checkedRequirements, ARCHIVED_SECTION_DATA.uid);
    const edits = requirementsToMove.map((requirement) => {
      return {
        requirement_response_uid: requirement.requirementResponseUid,
        remove_from_section: true,
        is_compliant: false
      };
    });
    requirementsToMove.forEach((requirement) => {
      const fromIndex = outlineState.requirements[requirement.sectionUid]?.findIndex(
        (_requirement) => _requirement.requirementResponseUid === requirement.requirementResponseUid
      );
      dispatch(moveRequirement({
        fromSectionUid: requirement.sectionUid,
        toSectionUid: ARCHIVED_SECTION_DATA.uid,
        toIndex: 0,
        fromIndex
      }));
    });
    archiveRequirementResponseMutation.mutate({
      edits,
      proposal_uid: proposalUid ?? ''
    }, {
      onError,
      onSuccess: onClearCheckedRequirements
    });
  }, [checkedRequirements, archiveRequirementResponseMutation]);

  const moveRequirementResponseMutation = useEnsisMutation('/app/requirement-responses', {
    requestType: 'patch',
    awaitRefetch: false,
    queryKeysToInvalidate: [
      '/app/requirement-responses' as RouteWithGet
    ].concat(allSectionRoutes),
    successMessage: (
      <Text fz='sm'> {'Requirements moved. '}
        <Anchor fz='sm' onClick={handleUndoMoveRequirements}>
          Undo
        </Anchor>
      </Text>),
    showFailureMessage: false
  });

  const [moveRequirementsOpened, setMoveRequirementsOpened] = useState(false);

  const isLoading = archiveRequirementResponseMutation.isPending || moveRequirementResponseMutation.isPending;
  const sectionUidsOfCheckedRequirements = new Set(checkedRequirements.map((requirement) => requirement.sectionUid));
  const sectionUidToDisable = sectionUidsOfCheckedRequirements.size === 1
    ? [...sectionUidsOfCheckedRequirements][0]
    : undefined;
  const disableArchiveButton = sectionUidToDisable === ARCHIVED_SECTION_DATA.uid || isLoading;

  const handleMoveRequirements = useCallback((newSectionUid: string) => {
    setPreviousRequirements(Object.values(outlineState.requirements).flat(1));
    const requirementsToMove = prepRequirementsForMove(checkedRequirements, newSectionUid);
    setMoveRequirementsOpened(false);
    const edits = requirementsToMove.map((requirement) => {
      return {
        requirement_response_uid: requirement.requirementResponseUid,
        new_section_uid: newSectionUid,
        new_ordinal: 1,
        is_compliant: false,
        section_text_references: []
      };
    });
    requirementsToMove.forEach((requirement) => {
      const fromIndex = outlineState.requirements[requirement.sectionUid]?.findIndex(
        (_requirement) => _requirement.requirementResponseUid === requirement.requirementResponseUid
      );
      dispatch(moveRequirement({
        fromSectionUid: requirement.sectionUid,
        toSectionUid: newSectionUid,
        toIndex: 0,
        fromIndex
      }));
    });
    moveRequirementResponseMutation.mutate({
      proposal_uid: proposalUid ?? '',
      edits
    }, {
      onSuccess: onClearCheckedRequirements,
      onError
    });
  }, [moveRequirementResponseMutation.mutate, outlineState, checkedRequirements]);

  const moveRequirementsButton = (
    <Popover
      opened={moveRequirementsOpened}
      onChange={setMoveRequirementsOpened}
      styles={{
        dropdown: {
          padding: '4px 0px',
          textAlign: 'start',
          justifyContent: 'start'
        }
      }}
    >
      <Popover.Target >
        <Button
          disabled={isLoading}
          onClick={() => { setMoveRequirementsOpened((o) => !o); }}
          pl={16}
          leftSection={<FileMoveOutline />
          }>
          {moveButtonCopy}
        </Button>
      </Popover.Target>
      <Popover.Dropdown style={{ borderRadius: 8 }}>
        <MoveRequirementMenu
          topLevelSections={sections.filter((section) => section.parentSectionUid === undefined)}
          onClick={handleMoveRequirements}
          sectionUidToDisable={sectionUidToDisable}
        />
      </Popover.Dropdown>
    </Popover>
  );

  const archiveRequirementsButton = (
    <Button
      disabled={disableArchiveButton}
      leftSection={<Trash />}
      color='red'
      variant='outline'
      onClick={handleArchiveRequirements}
    >
      Archive
    </Button>
  );

  const deleteRequirementsButton = (
    <Button
      leftSection={<Trash />}
      color='red'
      variant='outline'
      onClick={onOpenDeleteRequirements}
    >
      Delete
    </Button>
  );

  const removeRequirementsButton = (
    requirementRemovalType === 'DELETE' ? deleteRequirementsButton : archiveRequirementsButton
  );

  return (
    <Group gap='16px' justify='end'>
      <Button disabled={isLoading} variant='outline' onClick={onClearCheckedRequirements}>
        {`Clear ${checkedRequirements.length}`}
      </Button>
      {moveRequirementsButton}
      <Divider orientation="vertical" />
      {removeRequirementsButton}
    </Group>
  );
};

export default BulkRequirementButtonGroup;
