import { useCallback } from 'react';
import { LoadingOverlay, Text } from '@mantine/core';

import ModalContent from '../../ModalContent';
import useEnsisMutation from '../../../hooks/useEnsisMutation';

interface DeleteProps {
  onClose: () => void
  name?: string
  onDeleteSuccess?: () => void
  resourceUid?: string
}

const DeleteResource: React.FC<DeleteProps> = (props: DeleteProps) => {
  const { name, resourceUid, onClose, onDeleteSuccess } = props;
  const deleteMutation = useEnsisMutation(
    `/app/organization-resources/${resourceUid}/data`,
    {
      requestType: 'delete',
      successMessage: 'Resource successfully removed',
      onSuccess: () => {
        if (onDeleteSuccess !== undefined) {
          onDeleteSuccess();
        }
        onClose();
      },
      queryKeysToInvalidate: ['/app/organization-resources']
    }
  );

  const onDelete = useCallback(() => { deleteMutation.mutate({}); }, []);

  return <ModalContent
    title='Are you sure?'
    primaryButton={{
      label: 'Remove',
      onClick: onDelete,
      color: 'red',
      disabled: deleteMutation.isPending
    }}
    secondaryButton={{
      onClick: onClose,
      label: 'Cancel',
      color: 'red'
    }}
    onClose={onClose}
  >
    <LoadingOverlay visible={deleteMutation.isPending} zIndex={1000}/>
    <Text>
      Removing <Text span fw={700}> {name} </Text>will stop its contribution to content
     generation across all proposals where it is currently in use.
    </Text>
  </ModalContent>;
};

export default DeleteResource;
