import { Box } from '@mantine/core';

import { useState } from 'react';

import type { Proposal, RequirementResponse } from '../../../types/apiTypes';
import {
  EditorPaneViewSelector,
  OpportunityDocumentsViewer,
  type ViewSelectorProps
} from '../../../components';

type Props = {
  proposal: Proposal
  requirementResponses: RequirementResponse[]
  focusedRequirementUid: string
  onSelectRequirement?: (reqUid: string) => void
  onAddRequirement?: (requirementText: string, opportunityFileUid?: string) => void
} & ViewSelectorProps;

const DocumentsViewer: React.FC<Props> = (props: Props) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  return (
    <Box>
      <EditorPaneViewSelector {...props} />
      <Box
        h={'calc(100vh - 240px)'}
        style={{ overflow: 'hidden' }}
      >
        <OpportunityDocumentsViewer {...props}
          onClickFile={(fileIndex) => { setCurrentFileIndex(fileIndex); }}
          currentFileIndex={currentFileIndex}
          onClickHighlightedRequirement={props.onSelectRequirement}
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default DocumentsViewer;
