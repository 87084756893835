import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';

import { defaultModalProps } from '../../../utils/mantineUtils';
import {
  updateText as updateOutlineText
} from '../../../redux/OutlineEditorSlice';
import { ResponseSources } from '../../../components/Modals';
import {
  ResponseTextEditor,
  type ResponseTextEditorProps
} from '../../../components';
import classes from './ResponseEditor.module.css';
import { type RequirementResponse } from '../../../types/apiTypes';
import { withAnalyticsEvent } from '../../../utils/analyticsUtils';

type Props = {
  canEdit: boolean
  responseData: RequirementResponse
  shouldShowWarning: boolean
  outlineOutputFormat: string
  setShouldShowWarningFalse: () => void
  onGenerateText: () => void
} & Partial<ResponseTextEditorProps>;
const OutlineResponseEditor: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { responseData, onGenerateText, onEditorUpdate, ...restProps } = props;

  const [responseSourcesOpened, responseSourcesHandlers] = useDisclosure();

  const handleEditorUpdate = useCallback((newContent: string, wordCount: number) => {
    dispatch(updateOutlineText({
      uid: responseData?.uid ?? '',
      content: newContent,
      originalContent: responseData?.content ?? undefined
    }));
    if (onEditorUpdate !== undefined) {
      onEditorUpdate(newContent, wordCount);
    }
  }, [responseData?.content, responseData?.uid]);

  return (
    <>
      <Modal opened={responseSourcesOpened} {...defaultModalProps} size='90%'>
        <ResponseSources
          onClose={responseSourcesHandlers.close}
          requirementResponse={responseData}
        />
      </Modal>
      <ResponseTextEditor
        {...restProps}
        requirementResponseUid={responseData?.uid ?? ''}
        initialContent={responseData?.content ?? ''}
        proposalSectionUid={responseData?.proposal_section?.uid ?? ''}
        onEditorUpdate={handleEditorUpdate}
        contentStyleName={classes.content}
        onGenerateText={withAnalyticsEvent(onGenerateText, 'GenerateResponse')}
        onShowSources={withAnalyticsEvent(responseSourcesHandlers.open, 'ViewSources')}
      />
    </>
  );
};

export default OutlineResponseEditor;
