import { Group, Text, Stack, Select } from '@mantine/core';

import { useCallback, useState } from 'react';

import { getWordsPerPage } from '../../utils/formattingUtils';
import useEnsisMutation from '../../hooks/useEnsisMutation';

type SpacingSelection = '1' | '1.5' | '2';

interface Props {
  proposalUid: string
  initialSpacing: number
};

const viewSelectorData = [{
  value: '1',
  label: 'Single Space'
}, {
  value: '1.5',
  label: '1.5 Space'
}, {
  value: '2',
  label: 'Double Space'
}];

const convertSpacingSettingToNumber = (spacing?: SpacingSelection): number => (
  Number(spacing ?? '1')
);

const SelectProposalSpacing: React.FC<Props> = ({
  proposalUid,
  initialSpacing
}: Props) => {
  const [selectedSpacingValue, setSelectedSpacingValue] = useState<number>(initialSpacing);
  const wordsPerPage = getWordsPerPage(Number(selectedSpacingValue ?? '1'));

  const updateProposalMutation = useEnsisMutation(
    `/app/proposals/${proposalUid}/data`,
    {
      successMessage: 'Proposal Spacing Updated',
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/proposals/${proposalUid}/data`]
    }
  );

  const handleSubmit = useCallback((spacing: number) => {
    updateProposalMutation.mutate({ spacing });
  }, [selectedSpacingValue]);

  return (
    <Stack gap={8}>
      <Text fz='sm' ta='start' fw={600}> Page Length </Text>
      <Group>
        <Select
          data={viewSelectorData}
          value={selectedSpacingValue.toString()}
          radius={4}
          onChange={(value) => {
            const spacing = convertSpacingSettingToNumber(value as SpacingSelection);
            setSelectedSpacingValue(spacing);
            handleSubmit(spacing);
          }}
        />

        <Text style={{ color: 'var(--mantine-color-gray-6)' }}>
          {`Approximately ${wordsPerPage} words per page`}
        </Text>
      </Group>
    </Stack>
  );
};

export default SelectProposalSpacing;
