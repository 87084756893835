import { ScrollArea, Box } from '@mantine/core';
import { useCallback } from 'react';

import { CenteredLoader, SelectProposalResources } from '../../components';
import useEnsisMutation from '../../hooks/useEnsisMutation';
import useOrgPermissions from '../../hooks/useOrgPermissions';
import type { Proposal } from '../../types/apiTypes';
import WizardContent from './WizardContent';

interface Props {
  onClickNext: () => void
  onClickPrevious: () => void
  proposal?: Proposal
  organizationSlug: string
}

const SelectResources: React.FC<Props> = (props: Props) => {
  const { onClickNext, proposal, onClickPrevious } = props;

  const { isLoading: permissionsLoading } = useOrgPermissions();
  const referenceUids = proposal?.references?.map((resource) => resource.uid);

  const editProposalResources = useEnsisMutation(`/app/proposals/${proposal?.uid}/data`, {
    requestType: 'patch',
    showSuccessMessage: false,
    queryKeysToInvalidate: [`/app/proposals/${proposal?.uid}/data`]
  });

  const handleUpdateReferences = useCallback((uids: string[]) => {
    editProposalResources.mutate({
      reference_uids: uids
    });
  }, [editProposalResources.mutate]);

  if (permissionsLoading) {
    return <CenteredLoader style={{ height: '100vh', width: '100vw' }} />;
  };

  return (
    <Box w='100vw' pt={24}>
      <WizardContent
        titleCopy='Select your resources'
        subtitleCopy='Choose which of your resources are relevant to this proposal.'
        onClickNext={onClickNext}
        onClickPrevious={onClickPrevious}
        nextButtonCopy='Done'
      >
        <ScrollArea h="calc(100vh - 250px)">
          <SelectProposalResources
            onUpdateReferences={handleUpdateReferences}
            allowImport={false}
            referenceUids={referenceUids ?? []}
            tableContainerStyle={{ height: 'calc(100vh - 380px)', minHeight: 150 }}
          />
        </ScrollArea>
      </WizardContent>
    </Box>
  );
};

export default SelectResources;
