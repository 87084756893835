/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgHelpCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#868E96"
      d="M7.25 12.5h1.5V11h-1.5zM8 .5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15M8 14c-3.307 0-6-2.693-6-6s2.693-6 6-6 6 2.693 6 6-2.693 6-6 6M8 3.5a3 3 0 0 0-3 3h1.5a1.5 1.5 0 0 1 3 0c0 1.5-2.25 1.313-2.25 3.75h1.5C8.75 8.563 11 8.375 11 6.5a3 3 0 0 0-3-3"
    />
  </svg>
);
export default SvgHelpCircle;
