import { Text, Stack, Select } from '@mantine/core';

import { useCallback, useState } from 'react';

import useEnsisMutation from '../../hooks/useEnsisMutation';
import type { Proposal } from '../../types/apiTypes';

type FormatSelection = 'BULLET_POINTS' | 'PARAGRAPH';

interface Props {
  proposal: Proposal
};

const formatSelectorData = [{
  value: 'BULLET_POINTS',
  label: 'Bullet Points'
}, {
  value: 'PARAGRAPH',
  label: 'Paragraph'
}];

const SelectResponseFormat: React.FC<Props> = ({ proposal }: Props) => {
  const [selectedFormatValue, setSelectedFormatValue] = useState<FormatSelection>(
    proposal.outline_output_format as FormatSelection ?? 'BULLET_POINTS'
  );

  const updateProposalMutation = useEnsisMutation(
    `/app/proposals/${proposal?.uid}/data`,
    {
      successMessage: 'Outline Response Format Updated',
      requestType: 'patch',
      queryKeysToInvalidate: [`/app/proposals/${proposal?.uid}/data`]
    }
  );

  const handleSubmit = useCallback((format: FormatSelection) => {
    updateProposalMutation.mutate({ outline_output_format: format });
  }, []);

  return (
    <Stack gap={8}>
      <Text fz='sm' ta='start' fw={600}>Outline Response Format</Text>
      <Select
        data={formatSelectorData}
        value={selectedFormatValue}
        radius={4}
        onChange={(value) => {
          setSelectedFormatValue(value as FormatSelection);
          handleSubmit(value as FormatSelection);
        }}
      />
    </Stack>
  );
};

export default SelectResponseFormat;
