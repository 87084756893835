import { Box, Text, Tooltip, Group, Center } from '@mantine/core';

import { AlertCircle } from '../../icons';

interface Props {
  onGenerateContent?: () => void
  onClick: () => void
  alertText?: string
}
const ResponseEditorPlaceholder: React.FC<Props> = (props: Props) => {
  const { onClick, onGenerateContent, alertText } = props;

  const alertIcon = (
    <Tooltip
      events={{ hover: true, touch: true, focus: false }}
      label={alertText}
      position='top-start'
      arrowOffset={8}
      withArrow
      multiline
    >
      <Center>
        <AlertCircle />
      </Center>
    </Tooltip>
  );

  const shouldShowAlert = alertText !== undefined;

  return (
  <Box
    onClick={onClick}
    bg='var(--mantine-color-lightPurple-0)'
    p={'17px 16px'}
    style={{ borderRadius: '4px' }}
  >
    <Group align='center'>
      { shouldShowAlert && alertIcon }
      <Text c='var(--mantine-color-gray-6)'>
        <Text
          span
          c='var(--mantine-color-gray-6)'
          style={{ borderBottom: '1.5px solid var(--mantine-color-gray-6' }}
        >
          {'Write content'}
        </Text>
        {' or '}
        <Text
          span
          c='var(--mantine-color-lightPurple-5)'
          style={{ borderBottom: '1.5px solid var(--mantine-color-lightPurple-5', cursor: 'pointer' }}
          onClick={(event) => {
            if (onGenerateContent !== undefined) {
              onGenerateContent();
            }
            event.stopPropagation();
          }}
        >
          {'generate with AI'}
        </Text>
      </Text>
    </Group>
  </Box>);
};

export default ResponseEditorPlaceholder;
