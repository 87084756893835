/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
import type { SVGProps } from "react";
const SvgAlertCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#EF4050"
      d="M9 13h2v2H9zm0-8h2v6H9zm1-5C4.47 0 0 4.5 0 10A10 10 0 1 0 10 0m0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16"
    />
  </svg>
);
export default SvgAlertCircle;
