/* eslint-disable max-len */
import { createTheme, Text, TextInput, Tabs, Title, Textarea, Tooltip, Chip, Pill } from '@mantine/core';
import '@fontsource-variable/inter';

const theme = createTheme({
  colors: {
    green: ['#FFFFFF', '#E5EEEB', '#A6C5BB', '#58947E', '#267156', '#005838', '#004B30', '#003925', '#001F14', '#000906'],
    red: ['#FDF9F9', '#FDECED', '#F9BDC2', '#F5838D', '#F15D69', '#EF4050', '#CB3644', '#9B2A34', '#54161C', '#180608'],
    darkPurple: ['#FFFFFF', '#EAE8EF', '#B6B0C6', '#786B94', '#4D3E74', '#2F1C5B', '#28184D', '#1F123B', '#100A20', '#050309'],
    lightPurple: ['#F9F7FC', '#F1EBF7', '#CDBAE3', '#A37ECC', '#8657BC', '#7139B0', '#602F96', '#492572', '#28143E', '#0B0612'],
    greyPurple: ['#F9F8FA', '#F3F2F5', '#D6D0DC', '#B3A9BE', '#9D8DA9', '#8A7A9A', '#756883', '#5A4F64', '#302B36', '#0E0C0F']
  },
  primaryShade: 5,
  primaryColor: 'lightPurple',
  defaultRadius: 24,
  fontFamily: 'Inter Variable, sans-serif',
  // using default font-sizes + line heights: https://mantine.dev/theming/typography/#font-sizes
  headings: {
    fontFamily: 'Inter Variable, sans-serif',
    sizes: {
      h1: {
        fontWeight: '300',
        fontSize: '96px'
      },
      h2: {
        fontWeight: '300',
        fontSize: '60px'
      },
      h3: {
        fontWeight: '400',
        fontSize: '48px'
      },
      h4: {
        fontWeight: '600',
        fontSize: '32px'
      },
      h5: {
        fontWeight: '400',
        fontSize: '24px'
      },
      h6: {
        fontWeight: '500',
        fontSize: '20px'
      }
    }
  },
  components: {
    Chip: Chip.extend({
      defaultProps: {
        variant: 'outline'
      },
      styles: {
        label: {
          color: 'var(--mantine-color-lightPurple-5)',
          border: '1px solid var(--mantine-color-lightPurple-5)'
        }
      }
    }),
    Pill: Pill.extend({
      defaultProps: {
        size: 'lg',
        withRemoveButton: true
      },
      styles: {
        label: {
          color: 'var(--mantine-color-lightPurple-5)'
        },
        root: {
          backgroundColor: 'transparent',
          border: '1px solid var(--mantine-color-lightPurple-5)',
          borderRadius: 20
        },
        remove: {
          color: 'var(--mantine-color-lightPurple-5)'
        }
      }
    }),
    Text: Text.extend({
      defaultProps: {
        color: 'var(--mantine-color-darkPurple-9)',
        fz: 'md',
        fw: 400
      }
    }),
    Title: Title.extend({
      defaultProps: {
        c: 'var(--mantine-color-darkPurple-9)'
      }
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        radius: 4
      }
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        radius: 4
      }
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        radius: 4
      }
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        styles: {
          tab: {
            borderRadius: 0
          }
        }
      }
    })
  }
});

export default theme;
