import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { ResponseTextEditor } from '../../../components';
import { updateText as updateNarrativeText } from '../../../redux/NarrativeEditorSlice';
import classes from './ResponseEditor.module.css';

interface Props {
  proposalSectionUid: string
  canEdit: boolean
  initialContent?: string
  onGenerateNarrative: () => void
};

const NarrativeResponseEditor: React.FC<Props> = (props: Props) => {
  const { initialContent, onGenerateNarrative } = props;
  const dispatch = useDispatch();

  const onEditorUpdate = useCallback((newContent: string, wordCount: number) => {
    dispatch(updateNarrativeText({
      content: newContent,
      originalContent: initialContent,
      wordCount
    }));
  }, []);

  const onEditorCreate = useCallback((content: string, wordCount: number) => {
    dispatch(updateNarrativeText({
      wordCount
    }));
  }, []);

  return (
    <ResponseTextEditor
      {...props}
      contentStyleName={classes.narrativeContent}
      onEditorUpdate={onEditorUpdate}
      onEditorCreate={onEditorCreate}
      checkCompliance={true}
      onGenerateText={onGenerateNarrative}
    />
  );
};

export default NarrativeResponseEditor;
