import { RichTextEditor } from '@mantine/tiptap';

import classes from './GenerationFlow.module.css';

interface Props {
  onShowSources: () => void
  disabled?: boolean
}

const ShowSourcesControl: React.FC<Props> = (props: Props) => (
    <RichTextEditor.Control
      onClick={props.onShowSources}
      className={classes.showSourcesButton}
      style={{ borderRadius: '4px' }}
      disabled={props.disabled}
    >
      View Sources
    </RichTextEditor.Control>
);

export default ShowSourcesControl;
