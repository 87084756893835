import { Popover, ActionIcon, Button } from '@mantine/core';

import { useState } from 'react';

import { ThreeDotsVertical, Plus } from '../../icons';
import classes from './DropdownMenus.module.css';

interface Props {
  onOpenAddRequirement: () => void
}

const buttonClassNames = {
  inner: classes.popoverMenuButtonItem,
  section: classes.popoverMenuButtonLeftSection,
  root: classes.popoverMenuButtonItemRoot
};

const FileRequirementsViewDropdown: React.FC<Props> = (props: Props) => {
  const { onOpenAddRequirement } = props;
  const [opened, setOpened] = useState(false);

  return (
    <Popover
      opened={opened}
      onClose={() => { setOpened(false); }}
      position="bottom-start"
      classNames={{ dropdown: classes.popoverDropdown }}
    >
      <Popover.Target>
        <ActionIcon
          onClick={(event) => { setOpened((o) => !o); event.stopPropagation(); }}
          c='var(--mantine-color-gray-5)'
          variant='subtle'
        >
          <ThreeDotsVertical />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Button
          variant="subtle"
          classNames={buttonClassNames}
          leftSection={<Plus />}
          onClick={() => { onOpenAddRequirement(); setOpened(false); }}
        >
          Add Requirement
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FileRequirementsViewDropdown;
