import { useCallback } from 'react';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';

import GenerateControl from './GenerateControl';
import RevisionControl from './RevisionControl';
import ShowSourcesControl from './ShowSourcesControl';

interface Props {
  regenerateDisabled: boolean
  onClickRegenerate?: () => void
  onClickGenerate?: () => void
  showSourcesDisabled?: boolean
  onShowSources?: () => void
}
const ResponseEditorToolbar: React.FC<Props> = (props: Props) => {
  const { editor } = useRichTextEditorContext();
  const {
    onClickGenerate,
    onClickRegenerate,
    onShowSources,
    regenerateDisabled,
    showSourcesDisabled
  } = props;

  const firstItemBorderRadius = { borderRadius: '4px 0px 0px 4px' };
  const lastItemBorderRadius = { borderRadius: '0px 4px 4px 0px' };

  const getGenerateButton = useCallback(() => {
    if (onClickGenerate === undefined || onClickRegenerate === undefined) {
      return <></>;
    } else if (editor?.getText().length === 0) {
      return (
        <GenerateControl
          onClickGenerate={onClickGenerate}
        />
      );
    } else {
      return (
        <RevisionControl
          onClickRegenerate={onClickRegenerate}
          disabled={regenerateDisabled}
        />
      );
    }
  }, [onClickGenerate, onClickRegenerate, regenerateDisabled]);

  return (
    <RichTextEditor.Toolbar>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Bold style={firstItemBorderRadius}/>
        <RichTextEditor.Italic />
        <RichTextEditor.Underline />
        <RichTextEditor.Strikethrough style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
        <RichTextEditor.ControlsGroup>
        <RichTextEditor.H1 style={firstItemBorderRadius}/>
        <RichTextEditor.H2 />
        <RichTextEditor.H3 />
        <RichTextEditor.H4 style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.BulletList style={firstItemBorderRadius}/>
        <RichTextEditor.OrderedList style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.AlignLeft style={firstItemBorderRadius}/>
        <RichTextEditor.AlignCenter />
        <RichTextEditor.AlignRight style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      <RichTextEditor.ControlsGroup>
        <RichTextEditor.Superscript style={firstItemBorderRadius}/>
        <RichTextEditor.Subscript style={lastItemBorderRadius}/>
      </RichTextEditor.ControlsGroup>
      { onShowSources !== undefined && (
        <RichTextEditor.ControlsGroup>
          <ShowSourcesControl
            onShowSources={onShowSources}
            disabled={showSourcesDisabled}
          />
        </RichTextEditor.ControlsGroup>
      )}
      <RichTextEditor.ControlsGroup>
        { getGenerateButton() }
      </RichTextEditor.ControlsGroup>
    </RichTextEditor.Toolbar>
  );
};
export default ResponseEditorToolbar;
