import { useCallback, useState } from 'react';
import { ActionIcon, Box, Divider, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import type { SectionData } from './SectionEditor';
import { getSplitLocation } from '../../utils/stringUtils';
import classes from './Editor.module.css';
import { RouterNavLink } from '../../components';
import { Pencil, ExitApp, FormatListChecks, CollapseLeft } from '../../icons';
import { resetSelection } from '../../redux/NarrativeComplianceSlice';
import CollapsedEditorSideMenu from './CollapsedEditorSideMenu';
import { getProposalManagementRoute } from '../../utils/routerUtils';

interface SideMenuProps {
  sections: SectionData[]
  hasEditPermission: boolean
}

const EditorSideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const { hasEditPermission } = props;
  const { organization, proposalUid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showCollapsedView, setShowCollapsedView] = useState(false);
  const proposalManagementURL = getProposalManagementRoute(organization, proposalUid);
  const allProposalsURL = `/org/${organization}/proposals/`;

  const dispatch = useDispatch();
  const onClickNavigate = useCallback(() => {
    // reset compliance selection when navigating to prevent erroneous warning toast
    dispatch(resetSelection());
  }, []);

  if (showCollapsedView) {
    return (
      <CollapsedEditorSideMenu
        proposalManagementURL={proposalManagementURL}
        allProposalsURL={allProposalsURL}
        onClickExpand={() => { setShowCollapsedView(false); }}
      />
    );
  }

  const editButton = hasEditPermission && (
    <ActionIcon
      size='lg'
      variant='subtle'
      onClick={() => { navigate(proposalManagementURL); }}
    >
      <Pencil />
    </ActionIcon>
  );

  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column' }}
      pt={24}
      w={268}
      bg='var(--mantine-color-greyPurple-0)'
    >
      <Group ml={12} mr={12} mb={12} justify='space-between'>
        <Text
          fz='lg'
          ta={'start'}
          fw={600}
        >
          Outline
        </Text>
        <Group gap='xs'>
          {editButton}
          <ActionIcon variant='subtle' size='lg'>
            <CollapseLeft onClick={() => { setShowCollapsedView(true); }} />
          </ActionIcon>
        </Group>
      </Group>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ScrollArea ml={12} style={{ height: 'calc(100vh - 300px)', minHeight: '200px' }}>
          {props.sections.filter((section) => section.parentSectionUid === undefined).map((section) => (
            <div key={section.uid}>
              <RouterNavLink
                onClick={onClickNavigate}
                active={getSplitLocation(location).includes(section.uid)}
                classNames={{ root: classes.root, label: classes.label }}
                label={section.title}
                to={`/org/${organization}/proposals/${proposalUid}/edit/${section.uid}`}
              />
              {section.childSections?.map((childSection) => (
                <RouterNavLink
                  key={childSection.uid}
                  onClick={onClickNavigate}
                  active={getSplitLocation(location).includes(childSection.uid)}
                  classNames={{ root: classes.subsectionRoot, label: classes.subsectionLabel }}
                  label={childSection.title}
                  to={`/org/${organization}/proposals/${proposalUid}/edit/${childSection.uid}`}
                />
              ))}
            </div>
          ))}
        </ScrollArea>
        <Stack justify='flex-end' flex={1} gap='sm' pt={24} pb={20}>
          <RouterNavLink
            leftSection={<FormatListChecks />}
            key="manage"
            styles={{
              label: {
                fontWeight: 600
              }
            }}
            ml={12}
            to={proposalManagementURL}
            classNames={{ root: classes.root, label: classes.label }}
            label="Proposal Management"
          />
          <Divider />
          <RouterNavLink
            leftSection={<ExitApp />}
            ml={12}
            mt={8}
            key="exit"
            styles={{
              label: {
                fontWeight: 600
              }
            }}
            to={allProposalsURL}
            classNames={{ root: classes.root, label: classes.label }}
            label="Exit Proposal"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default EditorSideMenu;
